<template>
  <v-container>
    <v-form>
      <dropzone />
      <v-select
        v-model="selectResultType"
        :items="resultType"
        item-text="name"
        item-value="key"
        :error-messages="selectErrors"
        label="테스트 유형"
        required
        @change="$v.selectResultType.$touch()"
        @blur="$v.selectResultType.$touch()"
      />
      <v-select
        v-model="qcount"
        :items="qcountList"
        :error-messages="qcountErrors"
        label="질문수"
        required
        @change="$v.qcount.$touch()"
        @blur="$v.qcount.$touch()"
      />

      <v-text-field
        v-model="title"
        :error-messages="titleErrors"
        :counter="200"
        label="테스트 주제"
        required
        @input="$v.title.$touch()"
        @blur="$v.title.$touch()"
      />
      <v-text-field
        v-model="pd"
        label="제작자 이메일"
        disabled
      />

      <editors
        :typename="'theme'"
        @emitHasImg="emitContent"
      />

      <v-checkbox
        v-model="checkbox"
        :error-messages="checkboxErrors"
        label="중간저장이 안됩니다. 끝까지 진행해주세요."
        required
        @change="$v.checkbox.$touch()"
        @blur="$v.checkbox.$touch()"
      />

      <v-col>
        <p
          v-show="imgError"
          style="color: red"
        >
          사진을 첨부해주세요 !
        </p>
      </v-col>
      <v-row>
        <v-col cols="3">
          <v-btn
            block
            @click="clear"
          >
            RESET
          </v-btn>
        </v-col>
        <v-col cols="9">
          <v-btn
            class="elevation-4"
            color="primary"
            block
            @click="submit()"
          >
            질문 만들기
            <v-icon right> mdi-arrow-right </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, email } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],

  validations: {
    title: { required, maxLength: maxLength(200) },
    selectResultType: { required }, // email: { required, email },
    qcount: { required },
    checkbox: {
      checked(val) {
        return val
      },
    },
  },
  components: {
    Dropzone: () => import('@/components/create/Dropzone'),
    Editors: () => import('@/components/create/Editor'),
  },
  data: () => ({
    pd: '',
    title: '',
    email: '',
    instagram: '@',
    content: '',
    qcount: 4,
    selectResultType: 'MBTI',
    qcountList: [4, 8, 10, 12, 16, 20, 30],
    resultType: [],
    resultCount: 0,
    checkbox: false,
    hasImg: false,
    imgError: false,
  }),
  computed: {
    checkboxErrors() {
      const errors = []
      if (!this.$v.checkbox.$dirty) return errors
      !this.$v.checkbox.checked && errors.push('제작소 이용에 동의해주세요!')
      return errors
    },
    qcountErrors() {
      const errors = []
      if (!this.$v.qcount.$dirty) return errors
      !this.$v.qcount.required && errors.push('질문수를 선택해주세요.')
      return errors
    },
    selectErrors() {
      // const errors = []
      // if (!this.$v.selectAnswerType.$dirty) return errors
      // !this.$v.selectAnswerType.required && errors.push('답변 유형을 선택해주세요')
      // return errors
    },
    titleErrors() {
      const errors = []
      if (!this.$v.title.$dirty) return errors
      !this.$v.title.maxLength &&
        errors.push('테스트 주제는 200자까지 입력할 수 있습니다.')
      !this.$v.title.required && errors.push('테스트 주제를 입력해주세요')
      return errors
    },
  },
  watch: {
    selectResultType(val) {
      if (val === 'OX') {
        this.qcountList = [10]
        this.qcount = 10
      } else {
        this.qcountList = [4, 8, 10, 12, 16, 20, 30]
      }
    },
  },
  mounted() {
    this.resultType = this.$store.getters['data/getResultType']
    this.pd = this.$store.state.user.user.email
  },
  methods: {
    submit() {
      this.$v.$touch()
      if (this.$v.$invalid) {
        return
      }

      if (!localStorage.getItem('thumb')) {
        this.imgError = true
        setTimeout(() => {
          this.imgError = false
        }, 2000)
        return
      }

      this.goToCreate({
        title: this.title,
        pd: this.pd,
        qcount: this.qcount,
        resultType: this.selectResultType,
        content: this.content,
      })
    },
    clear() {
      this.$v.$reset()
      this.pd = ''
      this.title = ''
      this.instagram = '@'
      this.email = ''
      this.selectResultType = 'MBTI'
      this.checkbox = false
    },
    goToCreate(theme) {
      localStorage.setItem('theme', JSON.stringify(theme))
      this.$router.push('/add')
    },
    emitContent(content) {
      this.content = content
    },
  },
}
</script>

<style></style>
